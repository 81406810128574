import React from "react";
import InternalLayout from "../layouts/internallayout";
import styled from "styled-components";
import Seo from "../components/seo";

const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 5em 1em;
    position: relative;
    width: 100%;
    overflow: hidden;
`

const CancellationPolicy = () => (
    <InternalLayout>
        <Seo title="Cancellation Policy" />
        <Container>
            <h1>Cancellation Policy</h1>


            <p>
                This clinic has a 24 hour notice cancellation policy. By making an appointment with us, you agree to turn up to your appointment on time or pay a cancellation fee for the time your physiotherapist has allocated to you.
            </p>
            <p>
                In the past many patients have unfortunately abused this policy and we have been much too lenient with enforcing it, leading to the loss of many thousands of dollars each year. To a small business, this is a massive and unfair loss, a loss we can no longer sustain.
            </p>
            <p>
                We require at minimum 24 hours notice for appointment cancellation or changes to your time. This must be done via telephone or in person during clinic business hours only. We do not accept email or text for this. If you have a morning appointment, please call the day before. If you have a Monday appointment, changes must be made the previous Friday.
            </p>
            <p>
                From 2019, the cancellation policy will be strictly enforced. Cancellation fees are as follows:
            </p>
            <p>
                Less than 8 business hours notice or failure to attend: $70
            </p>
            <p>
                Less than 24 hours notice: $20
            </p>
            <p>
                Exceptions will be made for immediate emergencies only, (eg. medical emergencies, car break down on the way here), at the clinic’s discretion. This does not include last minute work commitments, family commitments, change of plans, forgetfulness, etc.
            </p>
            <p>
                Please do not rely on SMS reminders as they are a complimentary service only and are not failproof. You are responsible for remembering your appointment.
            </p>
            <p>
                Failure to comply may result in no longer being able to make an appointment here.
            </p>
            <p>
                We do not like to charge cancellation fees and we prefer to spend our time giving our patients the best care possible, so please don’t let this be you!
            </p>
            <p>
                Thank you for your understanding and cooperation.</p>
        </Container>

    </InternalLayout>
)

export default CancellationPolicy
